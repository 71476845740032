import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { nanolikeDataType } from 'shared/model/api.model';
import { graphType, IGraph } from 'shared/model/graph.model';
import { createGraph, fetchGraph, updateGraph } from 'shared/reducers/graphsSlice';
import { timeToOffsetString } from 'shared/utils/date-utils';
import { usePrevious } from 'shared/utils/react-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import Loading from 'shared/widgets/loading';
import GraphForm from './graphForm';
import { t } from 'i18next';

export interface IGraphFormResponse {
  graph_name: string;
  graphType: graphType;
  device_data_types: ILabelValueOption | ILabelValueOption[];
  metric_text?: string;
  start_offset_time?: ILabelValueOption;
  device_group: ILabelValueOption | ILabelValueOption[];
}

const CreateOrEditGraphForm = () => {
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ graphs }: IRootState) => graphs.updateSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const graph = useSelector(({ graphs }: IRootState) => graphs.graph);
  const loading = useSelector(({ graphs }: IRootState) => graphs.loading);
  const loadingDevices = useSelector(({ devices }: IRootState) => devices.loading);
  const history = useHistory();
  const { id, dashboardId } = useParams<{ id: string; dashboardId: string }>();

  const isNew = id === 'new' ? true : false;

  useEffect(() => {
    if (!isNew && id) {
      dispatch(fetchGraph(id));
    }
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (previousUpdateSuccess === false && updateSuccess) {
      history.push(`/dashboard/${dashboardId}`);
    }
  }, [dashboardId, history, updateSuccess, previousUpdateSuccess]);

  const onSubmit = async (responses: IGraphFormResponse) => {
    const name = responses.graph_name;
    const toCreateOrUpdate: IGraph = await getGraphViz(responses);
    toCreateOrUpdate.name = name;
    toCreateOrUpdate.dashboard_ids = [dashboardId as string];
    if (isNew) {
      dispatch(createGraph(toCreateOrUpdate));
    } else {
      const toUpdate = { graph_id: id, ...toCreateOrUpdate };
      dispatch(updateGraph(toUpdate));
    }
  };
  return (
    <Box p={1}>
      <Paper elevation={3}>
        {isNew || (!loading && !loadingDevices && graph) ? (
          <GraphForm onSubmit={onSubmit} />
        ) : (
          <Box p={4}>
            <Loading />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default CreateOrEditGraphForm;

export const getGraphViz = async (values: Partial<IGraphFormResponse>) => {
  const selectedGraphType = values.graphType as graphType;
  let dataType: nanolikeDataType[] = [];
  if (values.device_data_types) {
    if (Array.isArray(values.device_data_types)) {
      dataType = values.device_data_types.map(
        (option: ILabelValueOption<string>) => option.value as nanolikeDataType
      );
    } else {
      dataType.push(values.device_data_types.value);
    }
  }

  if (selectedGraphType === 'map') dataType = ['position'];
  if (selectedGraphType === 'filling') {
    dataType = ['daily_analysis'];
  }

  if (selectedGraphType === 'curve' && dataType.length === 0) {
    dataType = ['level_t'];
  }

  const selectedDevices = Array.isArray(values.device_group)
    ? values.device_group
    : [values.device_group];
  const metricText = values.metric_text ? values.metric_text : '';
  const startOffsetTime = values.start_offset_time ? values.start_offset_time.value : undefined;

  return getGraphFromResponse(
    selectedGraphType,
    selectedDevices,
    'group',
    dataType,
    metricText,
    startOffsetTime
  );
};
export const getGraphFromResponse = (
  graphType: graphType,
  selectedDevicesOrGroups: ILabelValueOption<string>[],
  groupLabel: string,
  dataType: nanolikeDataType[],
  metric_text: string | undefined,
  startOffsetTime: any
): IGraph => {
  const lastValue = graphType !== 'curve' && graphType !== 'filling';

  const graph: IGraph = {
    is_last_value: lastValue,
    device_data_types: dataType,
    type: graphType,
    group_ids: [],
    device_ids: [],
    metric_text
  };

  if (graphType === 'curve' || graphType === 'filling') {
    const start_offset_time = timeToOffsetString(startOffsetTime);
    graph.start_offset_time = start_offset_time;
  }

  selectedDevicesOrGroups.forEach((selectedDeviceOrGroup: ILabelValueOption<string>) => {
    if (selectedDeviceOrGroup.group === t(groupLabel)) {
      graph.group_ids.push(selectedDeviceOrGroup.value as string);
    } else {
      graph.device_ids.push(selectedDeviceOrGroup.value as string);
    }
  });
  return graph;
};
