import { nanolikeDataType } from './api.model';
import { IDeviceDataMinMax } from './device.model';

export type graphType = 'table' | 'map' | 'curve' | 'filling';
export const graphTypes: graphType[] = ['curve', 'filling', 'map', 'table'];

export type valueType = 'number' | 'number_positive' | 'float' | 'multiselect' | 'select';
export type operatorType = '<=' | '>=' | 'in' | 'not in' | '';

export interface IFilterCriterionValue {
  key: nanolikeDataType | 'status' | 'timestamp';
  operator?: operatorType;
  value: any[] | number | string;
}

export interface IGraph {
  device_ids: string[];
  group_ids: string[];
  type: graphType;
  device_data_types: nanolikeDataType[];
  name?: string;
  graph_id?: string;
  aggregate_operation_name?: string;
  aggregate_period_name?: string;
  colors?: string[];
  dashboard_ids?: string[];
  device_data_min_max?: IDeviceDataMinMax[];
  device_data_seuil_min_max?: object;
  from_timestamp?: string;
  is_last_value?: boolean;
  max_value?: number;
  metric_text?: string;
  min_value?: number;
  start_offset_time?: string;
  to_timestamp?: string;
  client_filter?: IFilterCriterionValue[];
  _graph_data?: object[];
}
