import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MapIcon from '@material-ui/icons/Map';
import BarChartIcon from '@material-ui/icons/BarChart';
import TableIcon from '@material-ui/icons/TableChart';
import CurveIcon from '@material-ui/icons/Timeline';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '100%',
      height: '32px'
    }
  })
);

export interface IGraphTypeProps {
  type: string;
  className?: string;
}

const GraphType: React.FunctionComponent<IGraphTypeProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { type, className } = props;
  // construt a object with translation for each type and Icon for each type
  const typeWithInfos = [
    { type: 'curve', translation: t('level_history'), Icon: CurveIcon },
    { type: 'filling', translation: t('daily_analysis'), Icon: BarChartIcon },
    { type: 'table', translation: t('table'), Icon: TableIcon },
    { type: 'map', translation: t('map'), Icon: MapIcon }
  ];
  return (
    <Box p={1} className={className}>
      {typeWithInfos
        .filter(t => t.type.includes(type))
        .map(t => (
          <Box key={t.type}>
            <Box>
              <t.Icon className={classes.icon} />
            </Box>
            <Box>{t.translation}</Box>
          </Box>
        ))}
    </Box>
  );
};

export default GraphType;
